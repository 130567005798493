import { MenuItem } from './sidebar-types';
import ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import UsersIcon from '../Icons/UsersIcon';
import LocationIcon from '../Icons/LocationIcon';
import AssigmentIcon from '../Icons/AssigmentIcon';
import ClientsIcon from '../Icons/ClientsIcon';
import NatureIcon from '../Icons/NatureIcon';
import ContainerIcon from '../Icons/ContainerIcon';
import BoxIcon from '../Icons/BoxIcon';
import RecycleIcon from '../Icons/RecycleIcon';
import ReportIcon from '../Icons/ReportIcon';
import QuestionIcon from '../Icons/QuestionIcon';
import GearIcon from '../Icons/GearIcon';
import React from 'react';
import { IUserContext } from '../../contexts/UserContext';
import HomeIcon from '../Icons/HomeIcon';

export const getNavigationItems = (
  isCollapsed: boolean,
  user: IUserContext,
): MenuItem[] => {
  const KNOWLEDGE_BASE_URL = 'https://kb.usefull.us/knowledgebase';
  const HELPDESK_URL = 'https://kb.usefull.us/knowledgebase/kb-tickets/new';
  const { dbUser } = user;
  const corporateClientId =
    dbUser?.userCorporateClients.length === 1
      ? dbUser.userCorporateClients[0].corporate_client_id
      : null;
  const campusSettingsPath = corporateClientId
    ? `${ROUTES.CORPORATE_CLIENTS}/${corporateClientId}`
    : ROUTES.CORPORATE_CLIENTS;

  return [
    {
      key: 'main',
      label:
        dbUser?.userPermissions.AdminLogin ||
        dbUser?.userPermissions.ManageUsers ||
        dbUser?.userPermissions.ManageLocations ||
        dbUser?.userPermissions.ViewLocations ||
        dbUser?.userPermissions.ManageAssignments ||
        dbUser?.userPermissions.ViewAssignments
          ? !isCollapsed
            ? 'Main'
            : ''
          : '',
      type: 'group',
      children: [
        ...(dbUser?.userPermissions.AdminLogin
          ? [
              {
                key: ROUTES.HOME,
                icon: <HomeIcon />,
                label: <Link to={ROUTES.HOME}>Home</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageUsers
          ? [
              {
                key: ROUTES.USERS,
                icon: <UsersIcon />,
                label: <Link to={ROUTES.USERS}>Users</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageLocations ||
        dbUser?.userPermissions.ViewLocations
          ? [
              {
                key: ROUTES.LOCATIONS,
                icon: <LocationIcon />,
                label: <Link to={ROUTES.LOCATIONS}>Locations</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageAssignments ||
        dbUser?.userPermissions.ViewAssignments
          ? [
              {
                key: ROUTES.ASSIGNMENTS,
                icon: <AssigmentIcon />,
                label: <Link to={ROUTES.ASSIGNMENTS}>Assignments</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageCorporateClients
          ? [
              {
                key: ROUTES.CORPORATE_CLIENTS,
                icon: <ClientsIcon />,
                label: <Link to={campusSettingsPath}>Campus Settings</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.AdminLogin && !dbUser.isCorporateAdmin
          ? [
              {
                key: ROUTES.SUPER_ADMIN_TV,
                icon: <NatureIcon />,
                label: (
                  <Link to={ROUTES.SUPER_ADMIN_TV + '?admin=true'}>
                    Master Impact Dashboard
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'inventory',
      label:
        dbUser?.userPermissions.ManageCups ||
        dbUser?.userPermissions.ViewCups ||
        dbUser?.userPermissions.ManageDeliveryBoxes ||
        dbUser?.userPermissions.ViewDeliveryBoxes ||
        dbUser?.userPermissions.ManageBins ||
        dbUser?.userPermissions.ViewBins
          ? !isCollapsed
            ? 'Inventory'
            : ''
          : '',
      type: 'group',
      children: [
        ...(dbUser?.userPermissions.ManageCups ||
        dbUser?.userPermissions.ViewCups
          ? [
              {
                key: ROUTES.CONTAINERS,
                icon: <ContainerIcon />,
                label: <Link to={ROUTES.CONTAINERS}>Containers</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageDeliveryBoxes ||
        dbUser?.userPermissions.ViewDeliveryBoxes
          ? [
              {
                key: ROUTES.BOXES,
                icon: <BoxIcon />,
                label: <Link to={ROUTES.BOXES}>Delivery Boxes</Link>,
              },
            ]
          : []),
        ...(dbUser?.userPermissions.ManageBins ||
        dbUser?.userPermissions.ViewBins
          ? [
              {
                key: ROUTES.BINS,
                icon: <RecycleIcon />,
                label: <Link to={ROUTES.BINS}>Return Bins</Link>,
              },
            ]
          : []),
      ],
    },
    {
      type: 'divider',
    },
    ...(dbUser?.userPermissions.BasicReports ||
    dbUser?.userPermissions.AdminReports
      ? [
          {
            key: ROUTES.REPORTS_DASHBOARD,
            icon: <ReportIcon />,
            label: <Link to={ROUTES.REPORTS_DASHBOARD}>Reports</Link>,
          },
        ]
      : []),
    ...(dbUser?.isCorporateAdmin
      ? [
          {
            key: ROUTES.TV_HOSTED_PAGE,
            icon: <NatureIcon />,
            label: (
              <Link
                to={
                  ROUTES.TV_HOSTED_PAGE +
                  `/${dbUser?.userCorporateClients?.[0]?.corporateClient.uuid}/internal?admin=true`
                }
              >
                Environmental Impact
              </Link>
            ),
          },
        ]
      : []),

    {
      key: 'help',
      icon: <QuestionIcon />,
      label: 'Help',
      children: [
        {
          key: 'knowledge_base',
          label: (
            <a href={KNOWLEDGE_BASE_URL} target="_blank" rel="noreferrer">
              USEFULL Knowledge Base
            </a>
          ),
        },
        {
          key: 'helpdesk',
          label: (
            <a href={HELPDESK_URL} target="_blank" rel="noreferrer">
              USEFULL Helpdesk
            </a>
          ),
        },
      ],
    },
    ...(dbUser?.userPermissions.ManageSettings
      ? ([
          {
            type: 'divider',
          },
          {
            key: 'settings',
            icon: <GearIcon />,
            label: 'Settings',
            children: [
              {
                key: ROUTES.MEMBERSHIPS,
                label: <Link to={ROUTES.MEMBERSHIPS}>Subscriptions</Link>,
              },
              {
                key: ROUTES.TOAST_MESSAGES,
                label: <Link to={ROUTES.TOAST_MESSAGES}>Toast Messages</Link>,
              },
            ],
          },
        ] as MenuItem[])
      : []),
  ];
};
